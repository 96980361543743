<template>
  <app-page-section title="Les Masterclass Act for Impact">
    <div class="m-auto grid w-full max-w-[950px] gap-16 md:gap-8">
      <section
        v-for="group in groupedByTeacherAndTopic"
        :key="group.teacher.id"
        class="grid gap-8 md:grid-cols-[250px_auto]"
      >
        <teacher-card
          class="aspect-video max-w-[400px] md:aspect-square"
          :teacher="group.teacher"
        />

        <div>
          <h2 class="mb-4 font-title text-lg md:text-2xl">
            {{ group.title }}
          </h2>

          <div class="grid gap-4">
            <template v-for="(course, index) in group.courses" :key="course.id">
              <app-divider v-if="index > 0" />
              <nuxt-link
                class="flex items-center justify-between gap-4 rounded ring-primary-50 transition hover:bg-primary-50 hover:ring-8"
                :to="{ name: 'courses-slug', params: { slug: course.slug } }"
              >
                <div>
                  <p class="mb-2">{{ course.title }}</p>

                  <div class="flex gap-2">
                    <course-session-date-chip
                      v-if="course.sessions[0]"
                      :session="course.sessions[0]"
                      size="small"
                    />
                    <course-format-chip :format="course.format" size="small" />
                    <course-online-type-chip
                      v-if="course.sessions[0]?.is_online"
                      size="small"
                    />
                  </div>
                </div>

                <app-button
                  color="dark"
                  size="small"
                  tag="span"
                  variant="outlined"
                  >{{ $t("app.cta.see") }}</app-button
                >
              </nuxt-link>
            </template>
          </div>
        </div>
      </section>
    </div>
  </app-page-section>
</template>

<script lang="ts" setup>
import type { CourseCardView } from "~/business-areas/course/composables/course-card-list.hook";

const properties = defineProps<{
  excludeCourseSlug?: string;
}>();

const { data } = useCourseCardList("act-for-impact", {
  track: "act-for-impact",
  includeNonIndexed: true,
  excludeSlugs: properties.excludeCourseSlug
    ? [properties.excludeCourseSlug]
    : undefined,
});

type TrackCourseGroup = {
  teacher: CourseCardView["teacher"];
  title: string;
  courses: CourseCardView[];
};

const groupedByTeacherAndTopic = computed<TrackCourseGroup[]>(() =>
  data.value?.reduce((groups, course) => {
    const matchingGroup = groups.find(
      (item) => item.teacher.id === course.teacher.id,
    );

    if (matchingGroup) {
      matchingGroup.courses.push(course);
      return groups;
    }

    const newGroup: TrackCourseGroup = {
      teacher: course.teacher,
      title: course.subject ?? "",
      courses: [course],
    };

    groups.push(newGroup);

    return groups;
  }, [] as TrackCourseGroup[]),
);
</script>
