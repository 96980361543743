<template>
  <app-page-section title="A propos de Act for Impact">
    <div class="grid gap-16 lg:grid-cols-2">
      <section
        v-for="item in aboutItems"
        :key="item.title"
        class="grid gap-x-6 gap-y-8 md:grid-cols-[200px_auto] lg:grid-cols-1"
      >
        <div
          class="flex aspect-[2.7] rounded bg-[--bgColor] p-2 md:aspect-square lg:aspect-[2.2] lg:p-12"
          :style="{ '--bgColor': item.color }"
        >
          <app-image alt="" class="m-auto max-h-full" :src="item.logo" />
        </div>

        <div>
          <h3 class="mb-2 text-xl font-extrabold">{{ item.title }}</h3>
          <p class="text-subtle" v-html="item.content" />
          <app-button
            class="mt-4"
            color="dark"
            :href="item.cta.href"
            size="lg"
            :to="item.cta.to"
            >{{ item.cta.label }}</app-button
          >
        </div>
      </section>
    </div>
  </app-page-section>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from "#vue-router";

const aboutItems: {
  title: string;
  content: string;
  logo: string;
  color: string;
  cta: {
    label: string;
    href?: string;
    to?: RouteLocationRaw;
  };
}[] = [
  {
    title: "Le programme Act For Impact",
    content:
      "Act For Impact est le dispositif BNP Paribas dédié aux entrepreneurs qui innovent dans le but d’avoir un impact positif, social ou environnemental, ils ont ainsi un modèle économique entièrement dédié à la résolution de cet impact. Les entrepreneurs à impact bénéficient d'un <strong>accompagnement</strong> bancaire spécifique, <strong>des solutions de financement innovantes</strong>, un outil de mesure d'impact et un accès à <strong>un réseau de partenaires</strong> clés. En France, Act For Impact s’appuie sur l’expertise de <strong>200 chargés d’affaires formés</strong> aux spécificités de l’entrepreneuriat à impact.",
    logo: "/images/logos/act_for_impact_full_dark.svg",
    color: "rgba(0, 169, 142, 0.10)",
    cta: {
      label: "Découvrir",
      href: "https://group.bnpparibas/nos-engagements/inclusion/entrepreneuriat-a-impact",
    },
  },

  {
    title: "Pollen, formez-vous avec les meilleurs",
    content:
      "Pollen est la nouvelle plateforme qui réinvente la formation et l’apprentissage tout au long de la vie professionnelle en donnant accès aux meilleurs experts.Notre ambition est de permettre à tous les talents d’acquérir les compétences clés pour façonner l’économie de demain, en apprenant directement des meilleurs professionnels du secteur de la tech et du business. Nous sélectionnons et accompagnons ces experts pour qu’ils transmettent leur savoir-faire et leurs méthodes, de manière actionnable et intéractive.",
    logo: "/images/logo_full_color.svg",
    color: "#EFEAFD",
    cta: {
      label: "Découvrir",
      to: "/",
    },
  },
];
</script>
